import styled from 'styled-components';
import { Box } from '@rebass/grid';

import { media } from 'utils/styleUtils';

const NavOverlay = styled(Box)`
  background-color: ${p => p.theme.white};
  position: fixed;
  top: ${p => p.theme.headerHeightMobile};
  left: 0;
  right: 0;
  transition: ${p => p.theme.quickTransition};
  max-height: ${p => `calc(100vh - ${p.theme.headerHeightMobile})`};
  overflow-y: auto;

  ${media.screenM`
    top: ${p => p.theme.headerHeightDesktop};
    max-height: ${p => `calc(100vh - ${p.theme.headerHeightDesktop})`};
  `}
`;

NavOverlay.defaultProps = {
  p: 6,
};

export default NavOverlay;
