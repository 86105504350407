import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css, withTheme } from 'styled-components';
import { rem } from 'polished';
import { Flex, Box } from '@rebass/grid';

import { media } from 'utils/styleUtils';
import Container from 'components/grid/container';
import ToggleVisibility from 'components/responsive/toggleVisibility';
import MobileMainNav from 'components/navs/mobileMainNav';
import MainNav from 'components/navs/mainNav';
import NavOverlay from 'components/navs/navOverlay';
import SiteOverlay from 'components/misc/siteOverlay';
import Hamburger from 'components/buttons/hamburger';
import ToggleOpacity from 'components/misc/toggleOpacity';
import LoginButton from 'components/header/loginButton';
import { TheCoachingManualLogo } from 'components/icons/logos';

const Root = styled.header`
  height: ${p => p.theme.headerHeightMobile};
  background-color: ${p => p.theme.black};
  color: ${p => p.theme.white};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  font-size: ${p => rem(`14px`, p.theme.baseFontSizeMobile)};

  ${media.screenM`
    font-size: ${p => rem(`14px`, p.theme.baseFontSizeDesktop)};
    height: ${p => p.theme.headerHeightDesktop};
  `}
`;

const Inner = styled(Container)`
  height: 100%;
  position: relative;
  background-color: ${p => p.theme.black};
  z-index: 1;
`;

const InnerFlex = styled(Flex)`
  height: 100%;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  height: 35px;
  width: 60px;
  flex-shrink: 0;

  ${media.screenM`
    height: 45px;
    width: 80px;
  `}

  > * {
    display: block;
  }
`;

const MobileNavOverlay = styled(NavOverlay)`
  transform: translateY(calc(-100% - 50px));

  ${p => p.active && css`
    transform: translateY(0);
  `}
`;

const DesktopToggleVisibility = styled(ToggleVisibility)`
  height: 100%;
`;

const HeaderRight = styled(Flex)`
  height: 100%;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuActive: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.href !== prevProps.location.href) {
      this.hideMobileMenu();
    }
  }

  hideMobileMenu = () => {
    this.setState({ mobileMenuActive: false });
  }

  handleHamburgerClick = (e) => {
    e.preventDefault();
    const { mobileMenuActive } = this.state;
    this.setState({ mobileMenuActive: !mobileMenuActive });
  }

  render() {
    const { user, theme } = this.props;
    const { mobileMenuActive } = this.state;

    return (
      <Root>
        <Inner px={[2, 2, 10, `100px`]}>
          <InnerFlex alignItems="center">
            <LogoWrapper to="/" as={Link}>
              <TheCoachingManualLogo fill={theme.white} height="100%" width="100%" />
            </LogoWrapper>

            <ToggleVisibility minWidth={theme.screenXL}>
              <Flex alignItems="center">
                <Box pr="3">
                  <LoginButton user={user} buttonSize="s" />
                </Box>
                
                <Hamburger onClick={this.handleHamburgerClick} active={mobileMenuActive} />
              </Flex>
            </ToggleVisibility>

            <DesktopToggleVisibility maxWidth={theme.screenXL}>
              <HeaderRight alignItems="center">
                <MainNav />

                <Box pl="2">
                  <LoginButton user={user} buttonSize="s" />
                </Box>
              </HeaderRight>
            </DesktopToggleVisibility>
          </InnerFlex>
        </Inner>

        {/* We have this outside of <Inner> to allow the mobile menu to slide in under the header */}
        <ToggleVisibility minWidth={theme.screenXL}>
          <SiteOverlay as={ToggleOpacity} active={mobileMenuActive} />
          <MobileNavOverlay active={mobileMenuActive} p={6}>
            <MobileMainNav />
          </MobileNavOverlay>
        </ToggleVisibility>
      </Root>
    );
  }
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(Header);
