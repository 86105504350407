import React from 'react';
import PropTypes from 'prop-types';

import { getImagePath } from 'utils/getPath';
import Button from 'components/buttons/button';
import Avatar from 'components/avatars/avatar';

const LoginButton = ({ user, buttonSize, avatarSize }) => {
  const loginLink = `https://app.thecoachingmanual.com/login`;

  return (
    <React.Fragment>
      {Object.keys(user).length ? (
        <a href={loginLink}>
          <Avatar src={getImagePath(user.image_url)} size={avatarSize} />
        </a>
      ) : (
        <Button as="a" href={loginLink} size={buttonSize}>Log In</Button>
      )}
    </React.Fragment>
  );
};

LoginButton.propTypes = {
  user: PropTypes.object.isRequired,
  buttonSize: PropTypes.string,
  avatarSize: PropTypes.string,
};

export default LoginButton;