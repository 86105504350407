function getPathname() {
  return window.location.host.toLowerCase();
}

export function getApiPath() {
  const pathname = getPathname();
  let apiUrl = `/`;

  if (pathname.indexOf(`localhost`) >= 0) {
    apiUrl = `http://localhost:8080/`;
  } else if (pathname.indexOf(`thecoachingmanual.com`) >= 0) {
    apiUrl = `https://api.thecoachingmanual.com/`;
  } else if (pathname.indexOf(`beans.youballer.io`) >= 0) {
    apiUrl = `https://api.beans.youballer.io/`;
  }

  return apiUrl;
}

export function getBasePath() {
  const pathname = getPathname();
  let baseUrl = `/`;

  if (pathname.indexOf(`localhost`) >= 0) {
    baseUrl = `http://localhost:8080`;
  } else if (pathname.indexOf(`thecoachingmanual.com`) >= 0) {
    baseUrl = `https://thecoachingmanual.com`;
  }

  return baseUrl;
}

export function getImagePath(imageUrl) {
  let returnUrl = null;

  if (imageUrl.indexOf(`storage.googleapis.com`) >= 0) {
    returnUrl = imageUrl;
  } else {
    returnUrl = `${getBasePath()}${imageUrl}`;
  }

  return returnUrl;
}
