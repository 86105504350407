import React from 'react';
import PropTypes from 'prop-types';

import BasicLink from 'components/links/basicLink';

const ExternalLink = ({ children, href, ...rest }) => {
  return (
    <BasicLink
      as="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </BasicLink>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  href: PropTypes.string.isRequired,
};

export default ExternalLink;
