import euclidBoldWoff from '../fonts/EuclidFlex-Bold-WebS.woff';
import euclidBoldWoff2 from '../fonts/EuclidFlex-Bold-WebS.woff2';
import euclidBoldItalicWoff from '../fonts/EuclidFlex-BoldItalic-WebS.woff';
import euclidBoldItalicWoff2 from '../fonts/EuclidFlex-BoldItalic-WebS.woff2';

import euclidMediumWoff from '../fonts/EuclidFlex-Medium-WebS.woff';
import euclidMediumWoff2 from '../fonts/EuclidFlex-Medium-WebS.woff2';
import euclidMediumItalicWoff from '../fonts/EuclidFlex-MediumItalic-WebS.woff';
import euclidMediumItalicWoff2 from '../fonts/EuclidFlex-MediumItalic-WebS.woff2';

import euclidRegularWoff from '../fonts/EuclidFlex-Regular-WebS.woff';
import euclidRegularWoff2 from '../fonts/EuclidFlex-Regular-WebS.woff2';
import euclidRegularItalicWoff from '../fonts/EuclidFlex-RegularItalic-WebS.woff';
import euclidRegularItalicWoff2 from '../fonts/EuclidFlex-RegularItalic-WebS.woff2';

import euclidLightWoff from '../fonts/EuclidFlex-Light-WebS.woff';
import euclidLightWoff2 from '../fonts/EuclidFlex-Light-WebS.woff2';
import euclidLightItalicWoff from '../fonts/EuclidFlex-LightItalic-WebS.woff';
import euclidLightItalicWoff2 from '../fonts/EuclidFlex-LightItalic-WebS.woff2';

export default `
  @font-face {
      font-family: 'euclid-flex';
      font-display: block;
      src:  url(${euclidBoldWoff2}) format('woff2'),
            url(${euclidBoldWoff}) format('woff');
      font-weight: bold;
      font-style: normal;
  }

  @font-face {
      font-family: 'euclid-flex';
      font-display: block;
      src:  url(${euclidBoldItalicWoff2}) format('woff2'),
            url(${euclidBoldItalicWoff}) format('woff');
      font-weight: bold;
      font-style: italic;
  }

  @font-face {
      font-family: 'euclid-flex';
      font-display: block;
      src:  url(${euclidMediumWoff2}) format('woff2'),
            url(${euclidMediumWoff}) format('woff');
      font-weight: 500;
      font-style: normal;
  }

  @font-face {
      font-family: 'euclid-flex';
      font-display: block;
      src:  url(${euclidMediumItalicWoff2}) format('woff2'),
            url(${euclidMediumItalicWoff}) format('woff');
      font-weight: 500;
      font-style: italic;
  }

  @font-face {
      font-family: 'euclid-flex';
      font-display: block;
      src:  url(${euclidRegularWoff2}) format('woff2'),
            url(${euclidRegularWoff}) format('woff');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'euclid-flex';
      font-display: block;
      src:  url(${euclidRegularItalicWoff2}) format('woff2'),
            url(${euclidRegularItalicWoff}) format('woff');
      font-weight: normal;
      font-style: italic;
  }

  @font-face {
      font-family: 'euclid-flex';
      font-display: block;
      src:  url(${euclidLightWoff2}) format('woff2'),
            url(${euclidLightWoff}) format('woff');
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
      font-family: 'euclid-flex';
      font-display: block;
      src:  url(${euclidLightItalicWoff2}) format('woff2'),
            url(${euclidLightItalicWoff}) format('woff');
      font-weight: 300;
      font-style: italic;
  }
`;
