import styled from 'styled-components';

const AvatarWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    background-color: ${p => p.theme.white};
    border: 1px solid ${p => p.theme.greyLight};
  }

  > * {
    position: relative;
    z-index: 1;
  }
`;

export default AvatarWrapper;