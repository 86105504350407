import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.p`
  margin: ${p => p.margin};
  font-weight: ${p => p.weight};
  color: ${p => p.color};
`;

const Text = ({ as, style, children, ...rest }) => {
  return (
    <Root as={as} className={style} {...rest}>
      {children}
    </Root>
  );
};

Text.propTypes = {
  as: PropTypes.string,
  style: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
};

export default Text;
