import React from "react";
import PropTypes from "prop-types";

import CoreLayout from "layouts/coreLayout";
import AppLayout from "layouts/appLayout";

const Layout = ({ children, pageContext, ...rest }) => {
  if (pageContext.layout === `app`) {
    return <AppLayout {...rest}>{children}</AppLayout>;
  }
  return <CoreLayout {...rest}>{children}</CoreLayout>;
};

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Layout;
