import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Bun = styled.span`
  background: white;
  display: block;
  height: 2px;
  margin-bottom: 5px;
  border-radius: 1px;
  transition: ${p => p.theme.quickTransition};

  &:last-child {
    margin-bottom: 0;
  }
 `;

const ActiveBun = styled(Bun)``;

const Filling = styled.div`
  height: 2px;
  margin-bottom: 5px;
  position: relative;

  ${ActiveBun} {
    position: absolute;
    width: 100%;
  }
`;

const Root = styled.button`
  width: 25px;
  outline: none !important;

  ${p => p.active && css`
    ${Bun} {
      opacity: 0;
    }

    ${ActiveBun} {
      &:first-child {
        transform: rotate(-45deg);
      }

      &:last-child {
        transform: rotate(45deg);
      }
    }
  `}
`;

const Hamburger = ({ onClick, active, ...rest }) => {
  return (
    <Root onClick={onClick} active={active} {...rest}>
      <Bun />
      <Filling>
        <ActiveBun />
        <ActiveBun />
      </Filling>
      <Bun />
    </Root>
  );
};

Hamburger.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

export default Hamburger;
