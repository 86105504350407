import { createGlobalStyle } from 'styled-components';
import { rem } from 'polished';

import fontFace from 'utils/fontFace';
import { media } from 'utils/styleUtils';

const GlobalStyle = createGlobalStyle`
  ${fontFace}

  *, *:before, *:after {
    box-sizing: border-box;
  }

  body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,p,blockquote,th,td {
    margin: 0;
    padding: 0;
  }

  html {
    font-size: ${p => p.theme.baseFontSizeMobile};

    ${media.screenM`
      font-size: ${p => p.theme.baseFontSizeDesktop};
    `}
  }

  html, body {
    overflow-x: hidden;
  }

  body {
    box-sizing: content-box;
    color: ${p => p.theme.black};
    font-family: ${p => p.theme.baseFont};
    font-size: 1rem;
    position: relative;
    padding-top: ${p => p.theme.headerHeightMobile};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${p => p.app && `overflow: hidden; }`};

    ${media.screenM`
      padding-top: ${p => p.theme.headerHeightDesktop};
    `}
  }

  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /* Undo default button styles */
  button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    cursor: pointer;
    margin: 0;
    background: none;
    border: none;
    border-radius: 0;
    color: inherit;
    padding: 0;
    text-align: left;
    transition: ${p => p.theme.linkTransition};
  }

  ::selection {
    background: #C3E4D6;
  }

  a {
    color: ${p => p.theme.primary};
    font-weight: ${p => p.weight || 500};
    text-decoration: none;
    transition: ${p => p.theme.linkTransition};

    &:hover,
    &:focus {
      color: ${p => p.theme.primaryDark};
    }
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin: 0 0 1em;
  }

  h1, .h1 {
    font-size: ${p => rem(`32px`, p.theme.baseFontSizeMobile)};
    line-height: 1.1;
    font-weight: bold;

    ${media.screenM`
      font-size: ${p => rem(`50px`, p.theme.baseFontSizeDesktop)};
      letter-spacing: 1px;
    `}
  }

  h2, .h2 {
    font-size: ${p => rem(`26px`, p.theme.baseFontSizeMobile)};
    line-height: 1.2;
    font-weight: bold;

    ${media.screenM`
      font-size: ${p => rem(`32px`, p.theme.baseFontSizeDesktop)};
    `}
  }

  h3, .h3 {
    margin: ${p => p.margin || `0 0 1em`};
    font-size: ${p => rem(`20px`, p.theme.baseFontSizeMobile)};
    line-height: 1.5;
    font-weight: 500;
    color: ${p => p.color};

    ${media.screenM`
      font-size: ${p => rem(`24px`, p.theme.baseFontSizeDesktop)};
    `}
  }

  h4, .h4 {
    margin: 0 0 1em;
    font-size: ${p => rem(`18px`, p.theme.baseFontSizeMobile)};
    line-height: 1.5;
    font-weight: 500;

    ${media.screenM`
      font-size: ${p => rem(`20px`, p.theme.baseFontSizeDesktop)};
    `}
  }

  p {
    line-height: 1.4;
    margin: 0 0 1em;
  }

  small {
    font-size: ${p => rem(`13px`, p.theme.baseFontSizeMobile)};

    ${media.screenM`
      font-size: ${p => rem(`13px`, p.theme.baseFontSizeDesktop)};
    `}
  }

  blockquote {
    color: ${p => p.theme.primary};
    font-weight: 500;

    + cite {
      display: block;
      margin: 1em 0 0 1em;
    }
  }

  cite {
    font-size: ${p => rem(`14px`, p.theme.baseFontSizeMobile)};

    ${media.screenM`
      font-size: ${p => rem(`14px`, p.theme.baseFontSizeDesktop)};
    `}
  }

  li {
    margin-bottom: 2em;
  }

  .text-right {
    text-align: right;
  }

  .w-100 {
    width: 100%;
  }

  #hs-eu-cookie-confirmation {
    top: auto !important;
    bottom: 0;
    position: fixed !important;
  }

  .modal {
    background-color: rgba(47, 49, 60, 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    transition: ${p => p.theme.quickTransition};
    overflow: auto;
    opacity: 0;
    visibility: hidden;
  }

  .modal--open {
    opacity: 1;
    visibility: visible;
  }

  .modal--closing {
    opacity: 0;
    visibility: hidden;
  }

  .modal__inner {
    background-color: ${p => p.theme.white};
    box-shadow: ${p => p.theme.boxShadowM};
    max-height: 100vh;
    text-align: left;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${media.screenS`
      max-height: calc(100vh - 100px);
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 50px;
      max-width: 700px;
      border-radius: ${p => p.theme.borderRadius};
    `}
  }

  .modal__body {
    overflow-y: auto;
  }

  /* App specific style overrides  */
  ${p => p.app && `
    body { 
      padding-top: 0 !important; 
    }

    .app-full-height-hero {
      height: 100vh !important;
    }
  `};
`;

export default GlobalStyle;
