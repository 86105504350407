import React from 'react';

import ExternalLink from 'components/links/externalLink';
import { PlayStoreDarkLogo } from 'components/icons/logos';

const PlayStoreLink = ({ ...rest }) => {
  return (
    <ExternalLink href="https://play.google.com/store/apps/details?id=thecoachingmanual.tcm">
      <PlayStoreDarkLogo {...rest} />
    </ExternalLink>
  );
};

export default PlayStoreLink;