import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import AvatarWrapper from 'components/avatars/avatarWrapper';

const Image = styled.img`
  border-radius: 50%;

  ${p => p.size === `xs` && css`
    height: 20px;
    width: 20px;
  `}

  ${p => p.size === `s` && css`
    height: 30px;
    width: 30px;
  `}

  ${p => p.size === `m` && css`
    height: 40px;
    width: 40px;
  `}

  ${p => p.size === `l` && css`
    height: 60px;
    width: 60px;
  `}

  ${p => p.size === `xl` && css`
    height: 100px;
    width: 100px;
  `}
`;

const Avatar = ({ size, ...rest }) => {
  return (
    <AvatarWrapper>
      <Image size={size} {...rest} />
    </AvatarWrapper>
  );
};

Avatar.defaultProps = {
  size: `m`,
};

Avatar.propTypes = {
  size: PropTypes.string,
};

export default Avatar;