import React from 'react';

import ExternalLink from 'components/links/externalLink';
import { AppStoreDarkLogo } from 'components/icons/logos';

const AppStoreLink = ({ ...rest }) => {
  return (
    <ExternalLink href="https://itunes.apple.com/us/app/the-coaching-manual/id1261404362?mt=8">
      <AppStoreDarkLogo {...rest} />
    </ExternalLink>
  );
};

export default AppStoreLink;