import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MinWidth = styled.div`
  @media (min-width: ${p => p.minWidth}px) {
    display: none;
  }
`;

const MaxWidth = styled.div`
  @media (max-width: ${p => p.maxWidth}px) {
    display: none;
  }
`;

const ToggleVisibility = ({ minWidth, maxWidth, children, ...rest }) => {
  if (maxWidth) {
    return (
      <MaxWidth maxWidth={maxWidth} {...rest}>
        {children}
      </MaxWidth>
    );
  }
  return (
    <MinWidth minWidth={minWidth} {...rest}>
      {children}
    </MinWidth>
  );
};

ToggleVisibility.propTypes = {
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default ToggleVisibility;
