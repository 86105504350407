import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;
  padding-left: 35px;
`;

const IconContainer = styled.div`
  > * {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

const IconWithText = ({ icon, children }) => {
  return (
    <Root>
      <IconContainer>
        {icon}
      </IconContainer>
      {children}
    </Root>
  );
};

IconWithText.propTypes = {
  icon: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default IconWithText;
