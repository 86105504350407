import styled, { css } from 'styled-components';

const InlineList = styled.ul`
  list-style: none;

  > * {
    float: left;
    margin-right: ${p => p.theme.spacer};

    ${p => p.spacing === `s` && css`
      margin-right: ${p.theme.spacerS};
    `}
  }
`;

export default InlineList;
