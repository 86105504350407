import styled, { css } from 'styled-components';

const StackedList = styled.ul`
  list-style: none;

  > * {
    margin-bottom: ${p => p.theme.spacer};

    ${p => p.spacing === `l` && css`
      margin-bottom: ${p.theme.spacerL};
    `}

    ${p => p.noLastMargin && css`
      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`;

export default StackedList;
