import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, withPrefix } from 'gatsby';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { focusVisible } from 'focus-visible';

import { getLoggedInUser } from 'actions/userActions';
import tcm from 'themes/tcm';
import GlobalStyle from 'components/globalStyle';
import Header from 'components/header/header';
import Footer from 'components/footer/footer';

// This Layout component automatically wraps every page component and doesn't re-render each time we navigate to a new page
// https://www.gatsbyjs.org/packages/gatsby-plugin-layout/
class CoreLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    getLoggedInUser().then((res) => {
      this.setState({ user: res.data });
    });
  }

  render() {
    const { data, location, children } = this.props;
    const { user } = this.state;

    return (
      <StaticQuery
        query={graphql`
          {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(staticData) => {
          // Static data from the StaticQuery (global site meta title)
          const {
            site: { siteMetadata: { title: globalMetaTitle } },
          } = staticData;

          // Dynamic page data passed via props
          let path;
          let pageMetaTitle;
          let description;

          if (data) {
            ({
              markdownRemark: { frontmatter: { path, title: pageMetaTitle, description } },
            } = data);
          }

          let title;
          // If we're not on the homepage and our page markdown has a specific meta title then use that
          if (pageMetaTitle) {
            // If the page meta title is longer than 48 characters then change the suffix to '| TCM' to prevent truncation in search results
            // Google now allows titles to be 70 characters, so 70 - 22 (the character length of the globalMetaTitle) = 48
            // https://seopressor.com/blog/google-title-meta-descriptions-length/
            if (pageMetaTitle.length > 48) {
              title = `${pageMetaTitle} | TCM`;
            } else {
              title = `${pageMetaTitle} | ${globalMetaTitle}`;
            }
            // If no meta title exists then use the global meta title specified in gatsby-config.js
          } else {
            title = globalMetaTitle;
          }

          return (
            <React.Fragment>
              <Helmet
                title={title}
                meta={[
                  { name: `description`, content: description },
                  { property: `og:title`, content: title },
                  { property: `og:description`, content: description },
                  { property: `og:type`, content: `website` },
                  { property: `og:url`, content: `https://www.thecoachingmanual.com${path}` },
                  { property: `og:image`, content: `https://www.thecoachingmanual.com${withPrefix(`/images/og-image.jpg`)}` },
                  { name: `twitter:card`, content: `summary_large_image` },
                  { name: `twitter:site`, content: `@CoachingManual` },
                  { name: `twitter:title`, content: title },
                  { name: `twitter:description`, content: description },
                  { name: `twitter:image`, content: `https://www.thecoachingmanual.com${withPrefix(`/images/og-image.jpg`)}` },
                ]}
              >
                <link rel="canonical" href={`https://www.thecoachingmanual.com${path}`} />
                
                {/* Google Business Social Links https://developers.google.com/search/docs/data-types/social-profile#guidelines */}
                <script type="application/ld+json">
                  {`
                    {
                      "@context": "http://schema.org",
                      "@type": "Organization",
                      "name": "The Coaching Manual",
                      "url": "https://www.thecoachingmanual.com",
                      "sameAs": [
                        "https://www.facebook.com/TheCoachingManual/",
                        "https://www.youtube.com/user/CoachingManualUK",
                        "https://twitter.com/CoachingManual",
                        "https://www.instagram.com/thecoachingmanual/",
                        "https://soundcloud.com/thecoachingmanual"
                      ]
                    }
                `}
                </script>

                {/* https://github.com/WICG/focus-visible */}
                <script src={focusVisible} />

                <html lang="en" />
              </Helmet>

              <ThemeProvider theme={tcm}>
                <React.Fragment>
                  <GlobalStyle theme={tcm} />
                  <Header location={location} user={user} />
                  {children}
                  <Footer />
                </React.Fragment>
              </ThemeProvider>
            </React.Fragment>
          );
        }}
      />
    );
  }
}

CoreLayout.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default CoreLayout;

export const query = graphql`
  fragment LayoutFragment on MarkdownRemark {
    frontmatter {
      path
      title
      description
    }
  }
`;
