import styled, { css } from 'styled-components';

const ToggleOpacity = styled.div`
  opacity: 0;
  visibility: hidden;

  ${p => p.active && css`
    opacity: 1;
    visibility: visible;
  `}
`;

export default ToggleOpacity;