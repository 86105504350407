import styled from 'styled-components';

import { media } from 'utils/styleUtils';

const SiteOverlay = styled.div`
  content: '':
  display: block;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: ${p => p.theme.headerHeightMobile};
  bottom: 0;
  left: 0;
  right: 0;
  transition: ${p => p.theme.quickTransition};

  ${media.screenM`
    top: ${p => p.theme.headerHeightDesktop};
  `}
`;

export default SiteOverlay;