import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Svg = styled.svg`
  fill: ${p => p.fill};
  display: inline-block;

  ${p => p.size === `xs` && css`
    height: 20px;
    width: 20px;
  `}

  ${p => p.size === `s` && css`
    height: 32px;
    width: 32px;
  `}

  ${p => p.size === `m` && css`
    height: 46px;
    width: 46px;
  `}

  ${p => p.size === `l` && css`
    height: 60px;
    width: 60px;
  `}

  ${p => p.size === `xl` && css`
    height: 100px;
    width: 100px;
  `}
`;

const IconPlaceholder = styled.div`
  background-color: ${p => p.fill || p.theme.greyMid};
  border-radius: 100%;
  display: inline-block;

  ${p => p.size === `xs` && css`
    height: 20px;
    width: 20px;
  `}

  ${p => p.size === `s` && css`
    height: 32px;
    width: 32px;
  `}

  ${p => p.size === `m` && css`
    height: 46px;
    width: 46px;
  `}

  ${p => p.size === `l` && css`
    height: 60px;
    width: 60px;
  `}

  ${p => p.size === `xl` && css`
    height: 100px;
    width: 100px;
  `}
`;

const Icon = ({ children, title, size, fill, height, width, viewBox }) => {
  if (children) {
    return (
      <Svg
        size={size}
        fill={fill}
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
      >
        <title>{title}</title>
        {children}
      </Svg>
    );
  }

  return <IconPlaceholder size={size} fill={fill} />;
};

Icon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  size: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
};

export default Icon;
