import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { Flex, Box } from '@rebass/grid';

import { isExternalLink } from 'utils/linkUtils';
import { media } from 'utils/styleUtils';
import Container from 'components/grid/container';
import StackedList from 'components/lists/stackedList';
import ListItem from 'components/lists/listItem';
import BasicLink from 'components/links/basicLink';
import ExternalLink from 'components/links/externalLink';
import Text from 'components/typography/text';
import Small from 'components/typography/small';
import IconWithText from 'components/icons/iconWithText';
import PlayStoreLink from 'components/links/playStoreLink';
import AppStoreLink from 'components/links/appStoreLink';
import { FacebookLogo, TwitterLogo, InstagramLogo, YoutubeLogo } from 'components/icons/logos';

const Root = styled.footer`
  background-color: ${p => p.theme.black};
  color: ${p => p.theme.white};
  font-size: ${p => rem(`14px`, p.theme.baseFontSizeMobile)};

  ${media.screenM`
    font-size: ${p => rem(`14px`, p.theme.baseFontSizeDesktop)};
  `}
`;

const SitemapListItem = styled(ListItem)`
  float: left;
`;

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allMarkdownRemark(
            filter: {frontmatter: {nav: {sitemap: {eq: true}}}},
            sort: {fields: [frontmatter___nav___order], order: ASC},
          ) {
            edges {
              node {
                frontmatter {
                  path
                  nav {
                    title
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let sitemapLinks;
        if (data.allMarkdownRemark) {
          sitemapLinks = data.allMarkdownRemark.edges;
        }

        return (
          <Root>
            <Container py={[8, 8, 10]}>
              <Flex flexWrap="wrap" mx={[-3, -3, -5]}>
                <Box width={[1, 1, 1, 1 / 2]} my={[3, 3, 5]}>
                  <StackedList>
                    {sitemapLinks && sitemapLinks.map((link) => {
                      const { node: { frontmatter: {
                        path,
                        nav: {
                          title,
                        },
                      } } } = link;

                      const externalLink = isExternalLink(path);

                      return (
                        <Box key={path} as={SitemapListItem} width={[1 / 2]} px={[3, 3, 5]}>
                          <BasicLink 
                            as={externalLink ? `a` : BasicLink}
                            to={path}
                            href={path}
                            white="true"
                          >
                            {title}
                          </BasicLink>
                        </Box>
                      );
                    })}
                    <Box as={SitemapListItem} width={[1 / 2]} px={[3, 3, 5]}>
                      <ExternalLink href="https://app.thecoachingmanual.com/login" white="true">Log In</ExternalLink>
                    </Box>
                  </StackedList>
                </Box>

                <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]} px={[3, 3, 5]} my={[3, 3, 5]}>
                  <StackedList>
                    <ListItem>
                      <ExternalLink href="https://www.facebook.com/TheCoachingManual" white="true">
                        <IconWithText icon={<FacebookLogo size="xs" />}>Facebook</IconWithText>
                      </ExternalLink>
                    </ListItem>

                    <ListItem>
                      <ExternalLink href="https://twitter.com/CoachingManual" white="true">
                        <IconWithText icon={<TwitterLogo size="xs" />}>Twitter</IconWithText>
                      </ExternalLink>
                    </ListItem>

                    <ListItem>
                      <ExternalLink href="https://www.youtube.com/user/CoachingManualUK" white="true">
                        <IconWithText icon={<InstagramLogo size="xs" />}>Instagram</IconWithText>
                      </ExternalLink>
                    </ListItem>

                    <ListItem>
                      <ExternalLink href="https://www.instagram.com/thecoachingmanual" white="true">
                        <IconWithText icon={<YoutubeLogo size="xs" />}>Youtube</IconWithText>
                      </ExternalLink>
                    </ListItem>
                  </StackedList>
                </Box>

                <Box width={[1, 1 / 2, 1 / 2, 1 / 4]} px={[3, 3, 5]} my={[3, 3, 5]}>
                  <Text>Need to get in touch?</Text>
                  <Text>Visit our <BasicLink to="/help-centre">Help Centre</BasicLink> to find the best way of contacting us and {`we'll`} be happy to help.</Text>
                  <Box mb="1">
                    <AppStoreLink height="40" />
                  </Box>
                  <PlayStoreLink height="40" />
                </Box>
              </Flex>

              <Box mt="5">
                <Small>&copy; Copyright The Coaching Manual, {new Date().getFullYear()} All Rights Reserved.</Small>
              </Box>
            </Container>
          </Root>
        );
      }}
    />
  );
};

export default Footer;
