import styled from 'styled-components';
import { Box } from '@rebass/grid';

const Container = styled(Box)`
  box-sizing: content-box;
  max-width: ${p => p.theme.maxWidth};
`;

Container.defaultProps = {
  mx: `auto`,
  px: [5, 5, 10, `100px`],
};

export default Container;
