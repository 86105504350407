// Breakpoints
export const screenS = 576;
export const screenM = 768;
export const screenL = 992;
export const screenXL = 1200;
export const screenXXL = 1600;

export const breakpoints = { screenS, screenM, screenL, screenXL, screenXXL };

const theme = {
  // Colours
  primary: `#45D695`,
  primaryDark: `#2CC681`,
  primaryLight: `#EDFBF5`,
  primaryLightText: `#88A79A`,
  secondary: `#4A71D5`,
  secondaryDark: `#285CE0`,
  black: `#2F313C`,
  blackDark: `#1B1B1B`,
  blackLight: `#40424a`,
  grey: `#999999`,
  greyMid: `#DDDDDD`,
  greyLight: `#E5E5E5`,
  greyBg: `#EDEFF3`,
  greyLightest: `#FAFAFA`,
  white: `#FFFFFF`,
  red: `#D1495B`,
  redDark: `#C13144`,
  yellow: `#F2C85A`,
  yellowDark: `#E8B941`,

  // Gradients
  primaryGradient: `45deg, #45D695, #4A71D5`,

  // Fonts
  baseFontSizeMobile: `15px`,
  baseFontSizeDesktop: `17px`,
  baseFont: `'euclid-flex', 'Helvetica Neue', sans-serif`,
  secondaryFont: `'Helvetica Neue', Arial, sans-serif`,

  // Breakpoints (for Rebass Grid https://github.com/rebassjs/grid#theming)
  screenS,
  screenM,
  screenL,
  screenXL,
  screenXXL,

  breakpoints: [
    `${screenS}px`,
    `${screenM}px`,
    `${screenL}px`,
    `${screenXL}px`,
    `${screenXXL}px`,
  ],

  // Spacing (for Rebass Grid https://github.com/rebassjs/grid#theming)
  spacerXS: `6px`,
  spacerS: `12px`,
  spacer: `18px`,
  spacerM: `24px`,
  spacerL: `30px`,
  space: [0, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60],

  // Transitions
  linkTransition: `all 0.2s ease`,
  quickTransition: `all 0.4s ease`,

  // Header Sizes
  headerHeightMobile: `50px`,
  headerHeightDesktop: `60px`,

  // Border Radius
  borderRadius: `4px`,

  // Box Shadow
  boxShadow: `0px 2px 5px 0px rgba(0, 0, 0, 0.15)`,
  boxShadowM: `0 2px 12px 0 rgba(0, 0, 0, 0.15)`,
  boxShadowL: `0px 5px 20px 0px rgba(0, 0, 0, 0.4)`,

  // Max Width
  maxWidth: `1800px`,

  // Form Sizes
  radioCheckboxSize: `20px`,
};

export default theme;
