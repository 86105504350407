import React from "react";
import styled, { css } from "styled-components";

import { media } from "utils/styleUtils";
import InlineButtons from "components/buttons/inlineButtons";
import Spinner from "./spinner";

const Root = styled.button`
  border: 1px solid transparent;
  background-color: ${p => p.theme.primary};
  color: ${p => p.theme.white};
  display: inline-block;
  border-radius: 500px;
  text-align: center;
  font-weight: 500;
  transition: ${p => p.theme.quickTransition};
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  width: ${p => p.width};
  white-space: nowrap;
  text-decoration: none;
  padding: ${p => p.theme.spacerS} ${p => p.theme.spacer};
  position: relative;
  line-height: 1;

  ${media.screenM`
    padding: 16px ${p => p.theme.spacerM};
  `}

  ${p => p.size === `s`
    && css`
    padding: ${p.theme.spacerXS} ${p.theme.spacerS};

    ${media.screenM`
      padding: ${p.theme.spacerXS} ${p.theme.spacerS};
    `}
  `}

  &:hover,
  &:focus {
    background-color: ${p => p.theme.primaryDark};
    color: ${p => p.theme.white};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${InlineButtons} & {
    margin-right: ${p => p.theme.spacer};

    &:last-child {
      margin-right: 0;
    }
  }

  ${p => p.loading
    && `
    color: transparent !important;
    pointer-events: none !important;
    opacity: 1 !important;
  `}

  ${p => p.variant === `secondary` && css`
    border-color: ${p.theme.greyLight};
    background-color: ${p.theme.white};
    color: ${p.theme.black};

    &:hover,
    &:focus {
      border-color: ${p.theme.grey};
      background-color: ${p.theme.greyLightest};
      color: ${p.theme.black};
    }
  `}
`;

const Button = ({ children, loading, disabled, ...rest }) => {
  return (
    <Root loading={loading} disabled={disabled || loading} {...rest}>
      {children}
      {loading && <Spinner />}
    </Root>
  );
};

export default Button;
