import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled, { withTheme } from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import InlineList from 'components/lists/inlineList';
import StackedList from 'components/lists/stackedList';
import MainNavListItem from 'components/navs/mainNavListItem';
import MainNavLink from 'components/navs/mainNavLink';
import MainNavExpanded from 'components/navs/mainNavExpanded';
import { DropdownIcon } from 'components/icons/icons';

const Root = styled.nav`
  height: 100%;
`;

const MainNavList = styled(InlineList)`
  height: 100%;
`;

const MainNavStyledLink = styled(MainNavLink)`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &:hover,
  &:focus {
    svg {
      fill: ${p => p.theme.primary} !important;
    }
  }

  &:after {
    content: '';
    background-color: transparent;
    position: absolute;
    height: 5px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.main-nav-link--active {
    color: ${p => p.theme.primary} !important;

    &:after {
      background-color: ${p => p.theme.primary} !important;
    }

    svg {
      fill: ${p => p.theme.primary} !important;
    }
  }
`;

const SubNavStyledLink = styled(MainNavLink)`
  &.main-nav-link--active {
    color: ${p => p.theme.primary} !important;
  }
`;

const MainNav = ({ theme }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          _topLevelLinks: allMarkdownRemark(
            filter: {frontmatter: {nav: {main: {eq: true}}}},
            sort: {fields: [frontmatter___nav___order], order: ASC},
          ) {
            edges {
              node {
                frontmatter {
                  path
                  type
                  nav {
                    title
                    mainExpandable
                  }
                }
              }
            }
          }
          _subLevelLinks: allMarkdownRemark(
            filter: {frontmatter: {nav: {subMain: {eq: true}}}},
            sort: {fields: [frontmatter___nav___order], order: ASC},
          ) {
            edges {
              node {
                frontmatter {
                  path
                  nav {
                    parent
                    title
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let topLevelLinks;
        let subLevelLinks;
        if (data._topLevelLinks) {
          topLevelLinks = data._topLevelLinks.edges;
        }
        if (data._subLevelLinks) {
          subLevelLinks = data._subLevelLinks.edges;
        }

        return (
          <Root>
            <MainNavList spacing="s">
              {topLevelLinks && topLevelLinks.map((link) => {
                const { node: { frontmatter: {
                  path,
                  type,
                  nav: {
                    title,
                    mainExpandable,
                  },
                } } } = link;

                const subNavArray = subLevelLinks.filter(item => item.node.frontmatter.nav.parent === type);

                return (
                  <MainNavListItem key={path}>
                    <MainNavStyledLink path={path} white="true">
                      <Flex as="div" px="2" alignItems="center">
                        {title}
                        {mainExpandable && (
                          <React.Fragment>
                            <Box ml="1" />
                            <DropdownIcon size="xs" fill={theme.white} />
                          </React.Fragment>
                        )}
                      </Flex>
                    </MainNavStyledLink>

                    {mainExpandable && (
                      <MainNavExpanded>
                        <StackedList noLastMargin="true">
                          {subNavArray && subNavArray.map((sub) => {
                            const { node: { frontmatter: {
                              path: subPath,
                              nav: {
                                title: subTitle,
                              },
                            } } } = sub;

                            return (
                              <li key={subPath}>
                                <SubNavStyledLink path={subPath} black="true">{subTitle}</SubNavStyledLink>
                              </li>
                            );
                          })}
                        </StackedList>
                      </MainNavExpanded>
                    )}
                  </MainNavListItem>
                );
              })}
            </MainNavList>
          </Root>
        );
      }}
    />
  );
};

MainNav.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(MainNav);
