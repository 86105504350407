import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import StackedList from 'components/lists/stackedList';
import MainNavLink from 'components/navs/mainNavLink';
import Heading from 'components/typography/heading';

const Root = styled.nav`
  text-align: center;
`;

const MainNavStyledLink = styled(MainNavLink)`
  display: inline-block;

  &.main-nav-link--active {
    color: ${p => p.theme.primary} !important;
  }
`;

const MobileMainNav = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allMarkdownRemark(
            filter: {frontmatter: {nav: {main: {eq: true}}}},
            sort: {fields: [frontmatter___nav___order], order: ASC},
          ) {
            edges {
              node {
                frontmatter {
                  path
                  nav {
                    title
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let mainNavLinks;
        if (data.allMarkdownRemark) {
          mainNavLinks = data.allMarkdownRemark.edges;
        }

        return (
          <Root>
            <StackedList spacing="l" noLastMargin>
              {mainNavLinks && mainNavLinks.map((link) => {
                const { node: { frontmatter: {
                  path,
                  nav: {
                    title,
                  },
                } } } = link;

                return (
                  <li key={path}>
                    <MainNavStyledLink path={path} black="true">
                      <Heading as="span" style="h3">{title}</Heading>
                    </MainNavStyledLink>
                  </li>
                );
              })}
            </StackedList>
          </Root>
        );
      }}
    />
  );
};

export default MobileMainNav;
