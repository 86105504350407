import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isExternalLink } from 'utils/linkUtils';
import BasicLink from 'components/links/basicLink';

const MainNavLink = ({ path, children, ...rest }) => {
  const externalLink = isExternalLink(path);
  
  // https://reach.tech/router/api/Link
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    const { className } = { ...rest };
    // This is annoying, but if the link is partially active we want to add the active class
    // along with any other classes so that the link is styled correctly.
    // As this function in run on the BasicLink component, as well as passing all current classnames we also
    // need to pass the BasicLink class name manually.
    // Without this the link would have all styles except for those defined in BasicLink.
    const partiallyActiveClass = classnames(className, BasicLink.componentStyle.lastClassName, `main-nav-link--active`);
    return isPartiallyCurrent ? { className: partiallyActiveClass } : null;
  };

  return (
    <BasicLink
      as={externalLink ? `a` : BasicLink}
      to={path}
      href={path}
      // activeClassName is a gatsby Link specific prop
      activeClassName="main-nav-link--active"
      getProps={isPartiallyActive}
      {...rest}
    >
      {children}
    </BasicLink>
  );
};
    
MainNavLink.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
      
export default MainNavLink;