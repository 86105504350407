import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from '@rebass/grid';

import MainNavListItem from 'components/navs/mainNavListItem';

const Root = styled(Box)`
  opacity: 0;
  visibility: hidden;
  transition: ${p => p.theme.linkTransition};
  position: absolute;
  background-color: ${p => p.theme.white};
  z-index: 1;
  border-radius: ${p => p.theme.borderRadius};
  box-shadow: ${p => p.theme.boxShadow};
  transform: translateY(-1px);
  min-width: 200px;

  ${MainNavListItem}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

const MainNavExpanded = ({ children, ...rest }) => {
  return (
    <Root {...rest}>
      {children}
    </Root>
  );
};

MainNavExpanded.defaultProps = {
  p: [5],
};

MainNavExpanded.propTypes = {
  children: PropTypes.object,
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default MainNavExpanded;