import { css } from 'styled-components';
import { breakpoints } from '../themes/tcm';

// https://www.styled-components.com/docs/advanced#media-templates
export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
