import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.h1`
  margin: ${p => p.margin};
  font-weight: ${p => p.weight};
  color: ${p => p.color};
`;

const Heading = ({ rank, style, children, ...rest }) => {
  return (
    <Root as={rank} className={style} {...rest}>
      {children}
    </Root>
  );
};

Heading.propTypes = {
  rank: PropTypes.string,
  style: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
};

export default Heading;
