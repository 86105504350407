import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import tcm from 'themes/tcm';
import GlobalStyle from 'components/globalStyle';

// This Layout component automatically wraps every page component and doesn't re-render each time we navigate to a new page
// https://www.gatsbyjs.org/packages/gatsby-plugin-layout/
const AppLayout = ({ children }) => {
  return (
    <ThemeProvider theme={tcm}>
      <React.Fragment>
        <GlobalStyle theme={tcm} app />
        {children}
      </React.Fragment>
    </ThemeProvider>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
