import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const BasicLink = styled(Link)`
  color: ${p => p.theme.primary};
  font-weight: ${p => p.weight || 500};
  text-decoration: none;
  transition: ${p => p.theme.linkTransition};

  &:hover,
  &:focus {
    color: ${p => p.theme.primaryDark};
  }

  ${p => p.white && css`
    color: ${p.theme.white};

    &:hover,
    &:focus {
      color: ${p.theme.primary};
    }
  `}

  ${p => p.black && css`
    color: ${p.theme.black};

    &:hover,
    &:focus {
      color: ${p.theme.primary};
    }
  `}
`;

export default BasicLink;
