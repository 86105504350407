// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-help-article-js": () => import("../src/templates/helpArticle.js" /* webpackChunkName: "component---src-templates-help-article-js" */),
  "component---src-templates-partner-page-js": () => import("../src/templates/partnerPage.js" /* webpackChunkName: "component---src-templates-partner-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-app-js": () => import("../src/pages/about-app.js" /* webpackChunkName: "component---src-pages-about-app-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-android-app-js": () => import("../src/pages/features/android-app.js" /* webpackChunkName: "component---src-pages-features-android-app-js" */),
  "component---src-pages-features-club-team-management-js": () => import("../src/pages/features/club-team-management.js" /* webpackChunkName: "component---src-pages-features-club-team-management-js" */),
  "component---src-pages-features-full-coaching-sessions-js": () => import("../src/pages/features/full-coaching-sessions.js" /* webpackChunkName: "component---src-pages-features-full-coaching-sessions-js" */),
  "component---src-pages-features-ios-app-js": () => import("../src/pages/features/ios-app.js" /* webpackChunkName: "component---src-pages-features-ios-app-js" */),
  "component---src-pages-features-season-plans-js": () => import("../src/pages/features/season-plans.js" /* webpackChunkName: "component---src-pages-features-season-plans-js" */),
  "component---src-pages-features-session-planner-js": () => import("../src/pages/features/session-planner.js" /* webpackChunkName: "component---src-pages-features-session-planner-js" */),
  "component---src-pages-help-centre-js": () => import("../src/pages/help-centre.js" /* webpackChunkName: "component---src-pages-help-centre-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-who-is-it-for-js": () => import("../src/pages/who-is-it-for.js" /* webpackChunkName: "component---src-pages-who-is-it-for-js" */),
  "component---src-pages-who-is-it-for-clubs-js": () => import("../src/pages/who-is-it-for/clubs.js" /* webpackChunkName: "component---src-pages-who-is-it-for-clubs-js" */),
  "component---src-pages-who-is-it-for-coaches-js": () => import("../src/pages/who-is-it-for/coaches.js" /* webpackChunkName: "component---src-pages-who-is-it-for-coaches-js" */),
  "component---src-pages-who-is-it-for-directors-of-coaching-js": () => import("../src/pages/who-is-it-for/directors-of-coaching.js" /* webpackChunkName: "component---src-pages-who-is-it-for-directors-of-coaching-js" */),
  "component---src-pages-who-is-it-for-governing-bodies-js": () => import("../src/pages/who-is-it-for/governing-bodies.js" /* webpackChunkName: "component---src-pages-who-is-it-for-governing-bodies-js" */)
}

