import { getApiPath } from 'utils/getPath';
import handleErrors from 'utils/handleErrors';

export async function getLoggedInUser() {
  try {
    const response = await fetch(`${getApiPath()}queryapi/user`, {
      method: `GET`,
      credentials: `include`,
      headers: {
        Accept: `application/json`,
      },
    });
    handleErrors(await response);
    const json = await response.json();
    return json;
  } catch (e) {
    throw e;
  }
}